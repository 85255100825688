<template>
  <div>
    <v-container>
      <!-- 得意先登録画面 -->
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>得意先登録</v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <!-- CSS得意先コード（新規登録）-->
                  <v-col cols="12" sm="12" v-if="!reqUpdate">
                    <v-autocomplete
                      v-model="currentParams.cssClientCode"
                      label="CSS得意先コード"
                      :items="cssClientList"
                      :rules="[required]"
                      item-text="comboItem"
                      item-value="code"
                      counter="40"
                      clearable
                      @change="updateCssCodeCombo"
                      tabindex="1"
                      :autofocus="!(this.isRequestedUpdate() || this.reqUpdate)"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- CSS得意先コード -->
                  <v-col cols="12" sm="12" v-if="reqUpdate">
                    <v-text-field
                      v-model="currentParams.cssClientCode"
                      label="CSS得意先コード"
                      persistent-hint
                      disabled
                      tabindex="2"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- CSS得意先名（新規登録）-->
                  <v-col cols="12" sm="12" v-if="!reqUpdate">
                    <v-text-field
                      v-model="currentParams.cssClientName"
                      label="CSS得意先名"
                      persistent-hint
                      :hint="`コードを選択すると自動設定されます`"
                      disabled
                      tabindex="3"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- CSS得意先名 -->
                  <v-col cols="12" sm="12" v-if="reqUpdate">
                    <v-text-field
                      v-model="currentParams.cssClientName"
                      label="CSS得意先名"
                      persistent-hint
                      disabled
                      tabindex="4"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス1 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email1"
                      label="メールアドレス1"
                      :rules="[required, limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="5"
                      :autofocus="(this.isRequestedUpdate() || this.reqUpdate)"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス2 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email2"
                      label="メールアドレス2"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="6"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス3 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email3"
                      label="メールアドレス3"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="7"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス4 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email4"
                      label="メールアドレス4"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="8"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス5 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email5"
                      label="メールアドレス5"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="9"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス6 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email6"
                      label="メールアドレス6"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="10"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス7 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email7"
                      label="メールアドレス7"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="11"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス8 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email8"
                      label="メールアドレス8"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="12"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス9 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email9"
                      label="メールアドレス9"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="13"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- メールアドレス10 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.email10"
                      label="メールアドレス10"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="14"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 作成日付 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.createdDate"
                      label="作成日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 更新日付 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.updatedDate"
                      label="更新日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- 登録 -->
              <v-btn
                color="secondary"
                v-on:click="onClickRegister"
                rounded
                class="my-2"
                tabindex="15"
              >
                <v-icon left> mdi-database-edit </v-icon>登録
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import {
  getM_TOKUISAKI,
  listM_TOKUISAKI,
} from "@/graphql/queries";
import { executeTransactSql, executeHanbaikanriSql } from "@/graphql/mutations";
import { CONFIRM_MESSAGE_UNSAVED_DATA, CONFIRM_MESSAGE_CLEAR_DATA, ALERT_MESSAGE_COMMON_ERROR, ALERT_COMPLETE_CRUD_DATA, ALERT_ALREADY_SAVED_DATA } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "ClientInsert",

  data: () => ({
    // 変数定義
    currentParams: {
      // // CSS得意先コード（コンボボックス用）
      // clientCssCombobox: null,
      // CSS得意先コード
      cssClientCode: null,
      // CSS得意先名
      cssClientName: null,
      // メールアドレス1
      email1: null,
      // メールアドレス2
      email2: null,
      // メールアドレス3
      email3: null,
      // メールアドレス4
      email4: null,
      // メールアドレス5
      email5: null,
      // メールアドレス6
      email6: null,
      // メールアドレス7
      email7: null,
      // メールアドレス8
      email8: null,
      // メールアドレス9
      email9: null,
      // メールアドレス10
      email10: null,
      // 作成日付
      createdDate: null,
      // 更新日付
      updatedDate: null,
    },

    // 未保存チェック用
    originalParams: {},

    // 新規登録用CSS得意先コードリスト
    cssClientList: [],

    // 修正画面表示用フラグ
    reqUpdate: false,

    // ログインユーザー情報
    loginUserInfo: null,

    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 文字数の制約 最大254文字
    limit_length254: (value) =>
      value == null || value.length <= 254 || "254文字以内で入力してください",
    // E-mailの正規表現
    regex_email: (value) =>
      !value ||
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ) ||
      "E-mailの形式で入力してください",
  }),
  /**
   * created ライフサイクルフック
   */
  created: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

     // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    
    // 修正画面表示用フラグを更新
    this.reqCssClientCode = this.$route.query.reqCssClientCode
    this.reqUpdate = this.isRequestedUpdate();
    // ローディングの開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.isSavedData()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!this.validateCheck()) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // ローディングの開始
        this.$store.commit("setLoading", true);

        // 修正時の判定
        if (this.isRequestedUpdate() || this.reqUpdate) {
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            // 画面遷移しない
            return;
          }
        } else {
          // 新規登録：入力データを登録
          if (!(await this.insertTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            // 画面遷移しない
            return;
          }
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      }
    }
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return this.loginUserInfo !== null;
      
    },
    /**
     * 初期化処理
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      if (this.isRequestedUpdate()) {
        // 既存データの更新の場合、マスタを照会し、初期値を更新

        // DBから取得した値を設定
        // 検索条件
        let condition = {
          BUMON_CD: this.loginUserInfo.BUMON_CD,
          CSS_TOKUISAKI_CD: this.reqCssClientCode,
        };

        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        // データ取得
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(getM_TOKUISAKI, condition)
          );

          // 結果判定
          if (result.data.getM_TOKUISAKI) {
            // データを取得できた場合
            // DBから取得した値を設定
            let resultData = result.data.getM_TOKUISAKI;
            this.currentParams = {
              departmentCode: resultData.BUMON_CD,
              cssClientCode: resultData.CSS_TOKUISAKI_CD,
              cssClientName: resultData.CSS_TOKUISAKI_NAME,
              email1: resultData.TOKUI_EMAIL_1,
              email2: resultData.TOKUI_EMAIL_2,
              email3: resultData.TOKUI_EMAIL_3,
              email4: resultData.TOKUI_EMAIL_4,
              email5: resultData.TOKUI_EMAIL_5,
              email6: resultData.TOKUI_EMAIL_6,
              email7: resultData.TOKUI_EMAIL_7,
              email8: resultData.TOKUI_EMAIL_8,
              email9: resultData.TOKUI_EMAIL_9,
              email10: resultData.TOKUI_EMAIL_10,
              createdDate: resultData.CREATE_DATE,
              createdUserName: resultData.CREATE_USER_NAME,
              updatedDate: resultData.UPDATE_DATE,
              updatedUserName: resultData.UPDATE_USER_NAME,
            };
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "getM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          } else {
            if (result.errors) {
              // データを取得できなかった場合
              // @TODO データ取得無しの場合の処理を以下に記載。
              // ログ出力(SQL実行でエラー)
              addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            } else {
              // データ0件時
              // ログ出力(SQL-end_0)
              addLog('INFO', {message: MSG.INFO["1005"], query: "getM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            }
          }
        } catch (error) {
          // Exception発生の場合
          // @TODO 異常系の処理を以下に記載。
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
          return false;
        }
      } else {
        // 新規登録時は販売管理システムのDBから得意先情報を取得
        // TODO：文字コード変換処理、得意先マスタ登録済みのコードは新規登録用コンボボックスから省く
        let condition = {
          BUMON_CD: this.loginUserInfo.BUMON_CD,
        };
        let registeredClientCode = [];
        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        // データ取得
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(listM_TOKUISAKI, condition)
          );

          // 結果判定
          if (result.data.listM_TOKUISAKI) {
            // データを取得できた場合
            // DBから取得した値を設定
            let resultData = result.data.listM_TOKUISAKI;
            for (const data of resultData) {
              registeredClientCode.push(data.CSS_TOKUISAKI_CD);
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "listM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          } else {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        } catch (error) {
          // Exception発生の場合
          // @TODO 異常系の処理を以下に記載。
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
          return false;
        }

        let sqlClientSearchList = [
          `SELECT TK_KANA, TK_TORCD, TK_TORNM1 FROM TOKUI` +
            ` WHERE TK_BASCD = ${this.loginUserInfo.BUMON_CD}` +
            ` AND TK_EDELKB <> 1` +
            " ORDER BY TK_KANA",
        ];

        let resultClientSearchData = await this.executeHanbaikanriSql(sqlClientSearchList);
        if (!resultClientSearchData[0]) {
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        this.cssClientList = [];
        let cssClientData = null
        for (const data of resultClientSearchData[0]) {
          cssClientData = registeredClientCode.find(
            (items) => items == data.TK_TORCD
          );
          if (cssClientData == null || cssClientData == void 0) {
            this.cssClientList.push({
              comboItem: data.TK_TORCD + "：" + data.TK_KANA,
              name: data.TK_TORNM1,
              code: data.TK_TORCD
            });
          } else {
            console.log("cssClientData (ALREADY EXIST) = " +cssClientData)
          }
        }
      }
      // 入力前のデータを保存
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 登録ボタン押下処理
     */
    async onClickRegister() {
      // メソッド名を定義
      const method_name="onClickRegister"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      if (!this.validateCheck()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 保存済みデータの場合、ダイアログを表示
      if (this.isSavedData()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の表示
      this.$store.commit("setLoading", true);
      // 修正ボタン押下もしくは連続登録しなかった場合
      if (this.isRequestedUpdate() || this.reqUpdate) {
        // 修正：入力データで更新
        if (!(await this.updateTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      } else {
        // 新規登録：入力データを登録
        if (!(await this.insertTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);

        if (confirm(CONFIRM_MESSAGE_CLEAR_DATA)) {
          // 連続登録の場合
          // ローディングの開始
          this.$store.commit("setLoading", true);
          // 画面上のデータを初期化
          this.currentParams = {
            cssClientCode: "",
            cssClientName: "",
            email1: "",
            email2: "",
            email3: "",
            email4: "",
            email5: "",
            email6: "",
            email7: "",
            email8: "",
            email9: "",
            email10: "",
            createdDate: "",
            updatedDate: "",
          };
          // 未保存チェック用配列を更新
          this.saveOriginalParams();

          // バリデーションエラーをリセット
          this.$refs.form.resetValidation();
        } else {
          // 修正画面用フラグを立てる
          this.reqUpdate = true;
        }
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 新規登録処理
     */
    async insertTable() {
      // メソッド名を定義
      const method_name="insertTable"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let email1 = !this.currentParams.email1 ? null : `'${this.currentParams.email1}'`;
      let email2 = !this.currentParams.email2 ? null : `'${this.currentParams.email2}'`;
      let email3 = !this.currentParams.email3 ? null : `'${this.currentParams.email3}'`;
      let email4 = !this.currentParams.email4 ? null : `'${this.currentParams.email4}'`;
      let email5 = !this.currentParams.email5 ? null : `'${this.currentParams.email5}'`;
      let email6 = !this.currentParams.email6 ? null : `'${this.currentParams.email6}'`;
      let email7 = !this.currentParams.email7 ? null : `'${this.currentParams.email7}'`;
      let email8 = !this.currentParams.email8 ? null : `'${this.currentParams.email8}'`;
      let email9 = !this.currentParams.email9 ? null : `'${this.currentParams.email9}'`;
      let email10 = !this.currentParams.email10 ? null : `'${this.currentParams.email10}'`;
      // DBに入力データ登録
      // 登録データ作成
      let sqlList = [
        `INSERT INTO M_TOKUISAKI VALUES (` +
          `${this.loginUserInfo.BUMON_CD},` +
          `'${this.currentParams.cssClientCode}',` +
          `'${this.currentParams.cssClientName}',` +
          `${email1},` +
          `${email2},` +
          `${email3},` +
          `${email4},` +
          `${email5},` +
          `${email6},` +
          `${email7},` +
          `${email8},` +
          `${email9},` +
          `${email10},` +
          `CURDATE(),` +
          `'${this.loginUserCode}',` +
          `CURDATE(),` +
          `'${this.loginUserCode}')`,
        `SELECT * FROM M_TOKUISAKI` +
          ` WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD}` +
          ` AND CSS_TOKUISAKI_CD = ${this.currentParams.cssClientCode}`
      ];
      let condition = {
        SQLs: sqlList,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              // DBから取得した値を設定
              let resultData = responseBody.data[1][0];
              this.currentParams = {
                cssClientCode: resultData.CSS_TOKUISAKI_CD,
                cssClientName: resultData.CSS_TOKUISAKI_NAME,
                email1: resultData.TOKUI_EMAIL_1,
                email2: resultData.TOKUI_EMAIL_2,
                email3: resultData.TOKUI_EMAIL_3,
                email4: resultData.TOKUI_EMAIL_4,
                email5: resultData.TOKUI_EMAIL_5,
                email6: resultData.TOKUI_EMAIL_6,
                email7: resultData.TOKUI_EMAIL_7,
                email8: resultData.TOKUI_EMAIL_8,
                email9: resultData.TOKUI_EMAIL_9,
                email10: resultData.TOKUI_EMAIL_10,
                createdDate: resultData.CREATE_DATE,
                createdUserName: resultData.CREATE_USER_NAME,
                updatedDate: resultData.UPDATE_DATE,
                updatedUserName: resultData.UPDATE_USER_NAME,
              };
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("insertTables : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("insertTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 登録済み得意先をコンボから削除
      let index = this.cssClientList.findIndex(element => element.code == this.currentParams.cssClientCode);
      this.cssClientList.splice(index, 1);
      console.log("remove index = "+ index + ", this.cssClientList.length = "+ this.cssClientList.length + ", this.cssClientList = " + JSON.stringify(this.cssClientList));

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 修正処理
     */
    async updateTable() {
      // メソッド名を定義
      const method_name="updateTable"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let email1 = !this.currentParams.email1 ? null : `'${this.currentParams.email1}'`;
      let email2 = !this.currentParams.email2 ? null : `'${this.currentParams.email2}'`;
      let email3 = !this.currentParams.email3 ? null : `'${this.currentParams.email3}'`;
      let email4 = !this.currentParams.email4 ? null : `'${this.currentParams.email4}'`;
      let email5 = !this.currentParams.email5 ? null : `'${this.currentParams.email5}'`;
      let email6 = !this.currentParams.email6 ? null : `'${this.currentParams.email6}'`;
      let email7 = !this.currentParams.email7 ? null : `'${this.currentParams.email7}'`;
      let email8 = !this.currentParams.email8 ? null : `'${this.currentParams.email8}'`;
      let email9 = !this.currentParams.email9 ? null : `'${this.currentParams.email9}'`;
      let email10 = !this.currentParams.email10 ? null : `'${this.currentParams.email10}'`;

      // 更新用データ生成
      let sqlList = [
        `UPDATE M_TOKUISAKI SET ` +
          `BUMON_CD = ${this.loginUserInfo.BUMON_CD},` +
          ` CSS_TOKUISAKI_CD = ${this.currentParams.cssClientCode},` +
          ` CSS_TOKUISAKI_NAME = '${this.currentParams.cssClientName}',` +
          ` TOKUI_EMAIL_1 = ${email1},` +
          ` TOKUI_EMAIL_2 = ${email2},` +
          ` TOKUI_EMAIL_3 = ${email3},` +
          ` TOKUI_EMAIL_4 = ${email4},` +
          ` TOKUI_EMAIL_5 = ${email5},` +
          ` TOKUI_EMAIL_6 = ${email6},` +
          ` TOKUI_EMAIL_7 = ${email7},` +
          ` TOKUI_EMAIL_8 = ${email8},` +
          ` TOKUI_EMAIL_9 = ${email9},` +
          ` TOKUI_EMAIL_10 = ${email10},` +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = '${this.loginUserCode}'` +
          ` WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD}` +
          ` AND CSS_TOKUISAKI_CD = ${this.currentParams.cssClientCode}`,
        `SELECT * FROM M_TOKUISAKI` +
          ` WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD}` +
          ` AND CSS_TOKUISAKI_CD = ${this.currentParams.cssClientCode}`
      ];
      let condition = {
        SQLs: sqlList,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              // DBから取得した値を設定
              let resultData = responseBody.data[0][0];
              this.currentParams = {
                cssClientCode: resultData.CSS_TOKUISAKI_CD,
                cssClientName: resultData.CSS_TOKUISAKI_NAME,
                email1: resultData.TOKUI_EMAIL_1,
                email2: resultData.TOKUI_EMAIL_2,
                email3: resultData.TOKUI_EMAIL_3,
                email4: resultData.TOKUI_EMAIL_4,
                email5: resultData.TOKUI_EMAIL_5,
                email6: resultData.TOKUI_EMAIL_6,
                email7: resultData.TOKUI_EMAIL_7,
                email8: resultData.TOKUI_EMAIL_8,
                email9: resultData.TOKUI_EMAIL_9,
                email10: resultData.TOKUI_EMAIL_10,
                createdDate: resultData.CREATE_DATE,
                updatedDate: resultData.UPDATE_DATE,
              };
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("updateTables : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("updateTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 未保存チェック用比較データの更新
     */
    saveOriginalParams() {
      // メソッド名を定義
      const method_name="saveOriginalParams"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParams = JSON.parse(JSON.stringify(this.currentParams));
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック
     */
    isSavedData() {
      // メソッド名を定義
      const method_name="isSavedData"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (
        JSON.stringify(this.currentParams) ==
        JSON.stringify(this.originalParams)
      );
    },
    /**
     * 既存データの更新チェック
     */
    isRequestedUpdate() {
      // メソッド名を定義
      const method_name="isRequestedUpdate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return this.reqCssClientCode !== void 0;
    },
    /**
     * バリデーションチェック
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * CSS得意先コードのコンボボックス更新処理
     */
    updateCssCodeCombo() {
      // メソッド名を定義
      const method_name="updateCssCodeCombo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (this.currentParams.cssClientCode !== null) {
        this.currentParams.cssClientName = this.cssClientList.find(
          (v) => v.code == this.currentParams.cssClientCode
        ).name;
      } else {
        this.currentParams.cssClientName = null;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 販売管理システム用関数の実行処理
     */
    async executeHanbaikanriSql(sqlList) {
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let condition = { SQLs: sqlList };
      console.log(
        "executeHanbaikanriSql：condition = " + JSON.stringify(condition)
      );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            console.log("executeHanbaikanriSql：error");
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return null;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
            }
            console.log("executeHanbaikanriSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return responseBody.data;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeHanbaikanriSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        console.log(
          "executeHanbaikanriSql : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
  },
};
</script>
